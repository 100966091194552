<div *ngIf="mode === 'view'; else formModeTemplate">
    <div *ngIf="!disabled" class="pi-control-edit-mode">
        <app-pi-form-view-response
            [element]="element"
            [theFormControl]="formControl"
            [displayValue]="stringValue"
        ></app-pi-form-view-response>
    </div>
</div>

<ng-template #formModeTemplate>
    <div *ngIf="controlFormGroup">
        <div *ngIf="!disabled" class="w-100 h-100 row pi-control-form-mode">
            <app-element-title [element]="element"></app-element-title>

            <app-loading-component [loading]="loading">
                <div class="form-check ms-3" [formGroup]="controlFormGroup">
                    <div *ngFor="let choice of element.choices">
                        <input
                            type="checkbox"
                            class="form-check-input"
                            [formControlName]="choice.value"
                            [attr.id]="this.elementUID + choice.value"
                            [attr.disabled]="element.readOnly"
                            [attr.aria-label]="choice.text"
                            (change)="onChoiceChange(choice.value)"
                            [name]="elementUID"
                        />
                        <div class="w-100">
                            <label
                                class="form-check-label"
                                [attr.for]="this.elementUID + choice.value"
                                [innerHTML]="getText(choice.text)"
                            ></label>
                            <span
                                *ngIf="choice.tooltip"
                                [tooltip]="getText(choice.tooltip)"
                                [options]="tooltipOptions"
                                class="ms-2"
                            >
                                <i aria-label="Info icon" class="fa-solid fa-info-circle"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </app-loading-component>
            <div class="mx-4" *ngIf="element.clearSelection">
                <app-button
                    class="mt-2"
                    (action)="clearSelection_Click()"
                    [disabled]="disabledClearSelection"
                    design="tertiary"
                    [iconConfig]="{
                        iconClasses: 'fa-regular fa-ban me-2',
                        iconFirst: true,
                    }"
                    [buttonText]="'Clear selection'"
                />
            </div>
        </div>

        <app-validator
            [control]="formControl"
            [customErrorMessages]="customErrorMessages"
            [warningControl]="warningControl"
            [customWarningMessages]="customWarningMessages"
        ></app-validator>
    </div>
</ng-template>
