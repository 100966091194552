import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SurveyModule } from './survey-management/survey.module';
import { LayoutComponent } from '@layout/layout.component';
import { emailtemplatemanagerModule } from './email_template_manager/email-template-manager.module';
import { TranslationIOModule } from './translation-io/translation-io.module';
import { PermissionManagementModule } from './permission-management/permission-management.module';
import { AuthGuard } from '@core/guards/auth.guard';
import { LogoutComponent } from './logout/logout.component';
import { ContentManagementModule } from './content-management/content-management.module';
import { WebassetsModule } from './webassets/webassets.module';
import { DeployManagementModule } from './deploy-management/deploy-management.module';
import { UserModule } from './user-management/user-management.module';
import { ScheduleManagementModule } from './schedule-management/schedule-management.module';
import { RegistrySiteManagementModule } from './registry-site-management/registry-site-management.module';
import { LoginComponent } from './login-managment/login/login.component';
import { LayoutCenteredComponent } from '@layout/layout-centered/layout-centered.component';

const routes: Routes = [
    { path: '', redirectTo: '/home', pathMatch: 'full' },
    {
        path: '',
        component: LayoutComponent,
        children: [
            {
                path: 'home',
                component: DashboardComponent,
                canActivate: [AuthGuard],
                data: { claims: ['h20ComposerUser', 'h20ComposerAdmin'] },
            },
            {
                path: 'surveys',
                loadChildren: () => SurveyModule,
                canActivate: [AuthGuard],
                data: { claims: ['h20ComposerUser', 'h20ComposerAdmin'] },
            },
            {
                path: 'emailtemplatemanager',
                loadChildren: () => emailtemplatemanagerModule,
                canActivate: [AuthGuard],
                data: { claims: ['h20ComposerAdmin'] },
            },
            {
                path: 'translation-io',
                loadChildren: () => TranslationIOModule,
                canActivate: [AuthGuard],
                data: { claims: ['h20ComposerUser', 'h20ComposerAdmin'] },
            },
            {
                path: 'permissionmanagement',
                loadChildren: () => PermissionManagementModule,
                canActivate: [AuthGuard],
                data: { claims: ['h20ComposerAdmin'] },
            },
            {
                path: 'schedulemanagement',
                loadChildren: () => ScheduleManagementModule,
                canActivate: [AuthGuard],
                data: { claims: ['h20ComposerAdmin'] },
            },
            {
                path: 'content-management',
                loadChildren: () => ContentManagementModule,
                canActivate: [AuthGuard],
                data: { claims: ['h20ComposerAdmin'] },
            },
            {
                path: 'webassets',
                loadChildren: () => WebassetsModule,
                canActivate: [AuthGuard],
                data: { claims: ['h20ComposerAdmin'] },
            },
            {
                path: 'deploymanagement',
                loadChildren: () => DeployManagementModule,
                canActivate: [AuthGuard],
                data: { claims: ['h20ComposerAdmin'] },
            },
            {
                path: 'usermanagement',
                loadChildren: () => UserModule,
                canActivate: [AuthGuard],
                data: { claims: ['h20ComposerAdmin'] },
            },
            {
                path: 'registrysite',
                loadChildren: () => RegistrySiteManagementModule,
                canActivate: [AuthGuard],
                data: { claims: ['h20ComposerAdmin'] },
            },
            {
                path: 'sb2',
                loadComponent: () =>
                    import('./sb2/modern-builder.component').then(
                        (mod) => mod.ModernBuilderComponent
                    ),
                canActivate: [AuthGuard],
                data: { claims: ['h20ComposerAdmin'] },
            },
        ],
    },
    {
        path: '',
        component: LayoutCenteredComponent,
        children: [
            {
                path: 'login',
                loadChildren: () =>
                    import('./login-managment/login.module').then((m) => m.LoginModule),
                data: { title: 'Sign In' },
            },
            {
                path: 'activate',
                loadChildren: () =>
                    import('./login-managment/login.module').then((m) => m.LoginModule),
                data: { title: 'Activate and Sign In' },
            },
            {
                path: 'forgot',
                loadChildren: () =>
                    import('./login-managment/login.module').then((m) => m.LoginModule),
                data: { title: 'Reset Password' },
            },

            {
                path: 'login/oauth2/authorize',
                loadChildren: () =>
                    import('./login-managment/login.module').then((m) => m.LoginModule),
                data: { title: 'Sign In' },
            },
        ],
    },
    { path: 'logout', component: LogoutComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
