import { Component, Injector, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import MiniSearch, { Options, SearchResult } from 'minisearch';
import { AbstractPiControlComponent } from '../abstract-pi-control/abstract-pi-control.component';
import { IFormElement } from '@h20-services/models/forms/IFormElement';
import { CommonService } from '@h20-services/common.service';
import { UuidService } from '@h20-services/uuid.service';
import { TranslateService } from '@ngx-translate/core';
import { ClaimService } from '@h20-services/claim.service';
import { ComposerRoleService } from '@h20-services/composer-role.service';
type Choice = { text: string | Record<string, string>; value: string };

type ChoiceLibraryEntry = {
    choices: Array<Choice>;
    $id: string;
    $name: string;
};
type ChoiceLibrary = Array<ChoiceLibraryEntry>;

type ChoiceSearchResult = {
    item: Array<Choice>;
    show: boolean;
    toggle: () => void;
    name: string;
    id: string;
};
const miniSearchOpts: Options = {
    fields: ['$name'],
    idField: '$id',
    storeFields: ['$name'],
    searchOptions: { fuzzy: 0.2, prefix: true },
};
@Component({
    selector: 'app-surveybuilder-choice-manager',
    templateUrl: './surveybuilder-choice-manager.component.html',
    styleUrls: ['./surveybuilder-choice-manager.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: SurveyBuilderChoiceManagerComponent,
        },
    ],
})
export class SurveyBuilderChoiceManagerComponent
    extends AbstractPiControlComponent<IFormElement, any>
    implements OnInit
{
    userClaims: any;
    constructor(
        private http: HttpClient,
        public injector: Injector,
        protected com_svc: CommonService,
        protected uuid_svc: UuidService,
        protected translate: TranslateService,
        private roleSvc: ComposerRoleService,
        private claimSvc: ClaimService
    ) {
        super(injector, com_svc, uuid_svc, translate);
        roleSvc.currentRole.subscribe(() => {
            this.userClaims = this.claimSvc.getUserClaims() || {};
        });
    }
    private static readonly CHOICESET_SOURCE_URI =
        'assets/survey-builder/qlib/choiceSets.0.0.1.json';

    private static choiceDb: ChoiceLibrary;
    loading = false;
    loadFailed = false;
    choices: Array<Choice> = [];
    results: Array<ChoiceSearchResult>;
    ms: MiniSearch<any>;
    searchText = '';

    ngOnInit(): void {}

    writeValue(val: Array<Choice>) {
        super.writeValue(val);

        this.choices = val;
    }

    remove(index: number) {
        this.choices.splice(index, 1);
        this.update();
    }

    add() {
        if (!this.choices) {
            this.choices = [{ text: '', value: '0' }];
            return;
        }

        if (this.choices.length > 0) {
            if (parseInt(this.choices[this.choices.length - 1].value) >= 99) {
                this.choices.splice(this.choices.length - 1, 0, {
                    text: '',
                    value: `${'' + (parseInt(this.choices[this.choices.length - 2].value) + 1)}`,
                });
            } else
                this.choices.push({
                    text: '',
                    value: `${'' + (parseInt(this.choices[this.choices.length - 1].value) + 1)}`,
                });
        } else this.choices = [{ text: '', value: '0' }];
        let uniqueObjects = {};
        this.choices.forEach((obj) => {
            uniqueObjects[obj.value] = obj;
        });

        this.choices = Object.values(uniqueObjects);
    }

    update() {
        super.writeValue(this.choices);

        super.notifyValueChange();
    }
}
