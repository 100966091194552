<div *ngIf="mode === 'view'; else formModeTemplate">
    <div *ngIf="!disabled" class="pi-control-edit-mode">
        <app-pi-form-view-response
            [element]="element"
            [theFormControl]="filesWithMetaData"
            [displayValue]="stringValue"
            [surveyId]="surveyId"
            [download]="'files'"
            [isRestrictedDownload]="isRestrictedDownload"
            (downloadButtonClicked)="downloadEventHandlerFunction($event)"
        ></app-pi-form-view-response>
    </div>
</div>
<ng-template #formModeTemplate>
    <div *ngIf="!disabled" class="pi-control-form-mode">
        <div class="form-label" [attr.for]="elementUID">
            <app-element-title [element]="element"></app-element-title>
        </div>

        <div>
            <div *ngFor="let fileItem of filesWithMetaData">
                <div class="my-1 text-muted d-flex" style="align-items: center">
                    <i
                        *ngIf="fileItem?.status !== 'deleted'"
                        attr.aria-label="{{ 'Icons.Remove' | translate }}"
                        class="fas fa-minus-square fa-lg me-1"
                        title="{{ 'Registry.RemoveFile' | translate }}"
                        (click)="removeFile(fileItem)"
                    ></i>
                    {{ fileItem.name || fileItem }}
                    <i
                        *ngIf="!!fileItem?.uploaded"
                        class="fa-solid fa-file-check fa-lg ms-1"
                        style="color: green"
                    ></i>
                    <i *ngIf="!!fileItem?.uploading" class="fal fa-spinner-third fa-spin fa-lg"></i>
                    <div *ngIf="fileItem?.status === 'deleted'">&nbsp;[deleted]</div>
                </div>
            </div>
        </div>
        <div>
            <div>
                <label
                    class="btn btn-primary label-theme text-nowrap"
                    [attr.id]="elementUID"
                    attr.aria-label="{{ 'Registry.SelectFile' | translate }}"
                >
                    {{ 'Registry.SelectFile' | translate }}
                    <input
                        *ngIf="this.element.restrictToSingleFile"
                        class="hidden"
                        type="file"
                        (change)="selectFile($event)"
                        [disabled]="singleFileUploaded"
                        [aria-describedby]="elementUID"
                        [accept]="acceptFileType"
                    />
                    <input
                        *ngIf="!this.element.restrictToSingleFile"
                        class="hidden"
                        type="file"
                        (change)="selectFile($event)"
                        multiple
                        [aria-describedby]="elementUID + 'desc'"
                        [accept]="acceptFileType"
                    />
                </label>
            </div>
            <br />

            <div [id]="elementUID + 'desc'">
                <span>
                    {{ element.fileExtensions }}
                </span>

                <br />
                <span *ngIf="element.fileSizeValue && element.fileSizeUnit"
                    >Maximum file size {{ element.fileSizeValue }} {{ element.fileSizeUnit }}</span
                >
            </div>
        </div>

        <app-validator [control]="formControl"></app-validator>
    </div>
</ng-template>
