import { Component, OnInit, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DDBEmailTemplates } from '@h20-services/ddb-email.service';
import { EmailMappingService } from '@h20-services/email-mapping.service';
import { IEmailMapping } from '@h20-services/models/emails/email_mapping';
import { ConfigService } from '@h20-services/config.service';
import { ColDef } from 'ag-grid-community';
import { AuthService } from '@h20-services/auth.service';
import { PulseAuth } from '@h20-services/models/PulseAuth';
import { ToastService } from '@h20-shared/services/toast.service';
import { EnvironmentService } from '@h20-services/environment.service';
import { ParticipantService } from '@h20-services/participant.service';
import { TranslateService } from '@ngx-translate/core';
import { composerUtils } from '@core/composerUtils';

@Component({
    selector: 'app-templates',
    templateUrl: './templates.component.html',
    styleUrls: ['./templates.component.scss'],
})
export class TemplatesComponent implements AfterViewInit {
    //Context: Context is used as a parameter within modal methods. It refers too the outside context being called since 'this' gets overwritten in the table.components.ts class to refer to the modal context.
    //Example: If we wanted to call a method within this class while using the modal, we cant use the keyword 'this', rather we need to use 'context'.
    DEFAULT_SOURCE_EMAIL: string = 'no-reply@healthie.net';
    templates: any[] = [];
    display_data: any[] = [];
    filtered_templates: any[] = [];
    prefix_filters: string[] = [];
    regions = [
        { name: 'US East (N. Virginia)', value: 'us-east-1' },
        { name: 'US East (Ohio)', value: 'us-east-2' },
        { name: 'US West (Oregon)', value: 'us-west-2' },
        { name: 'Asia Pacific (Mumbai)', value: 'ap-south-1' },
        { name: 'Asia Pacific (Seoul)', value: 'ap-northeast-2' },
        { name: 'Asia Pacific (Singapore)', value: 'ap-southeast-1' },
        { name: 'Asia Pacific (Sydney)', value: 'ap-southeast-2' },
        { name: 'Asia Pacific (Tokyo)', value: 'ap-northeast-1' },
        { name: 'Canada (Central)', value: 'ca-central-1' },
        { name: 'Europe (Frankfurt)', value: 'eu-central-1' },
        { name: 'Europe (Ireland)', value: 'eu-west-1' },
        { name: 'Europe (London)', value: 'eu-west-2' },
        { name: 'South America (São Paulo)', value: 'sa-east-1' },
    ];

    lst_registries: any[] = [];

    lst_email_mapping: IEmailMapping[] = [];
    dynamic_replacement_variables = [];
    selected_template: any;
    duplicate_template: any;
    form: FormGroup;
    user: PulseAuth;
    dynamic_replacement_form_group: FormGroup;
    send_form_is_valid: boolean = true;
    group_validation: boolean = false;
    user_type: string;

    delete_confirmation_modal: boolean = false;
    edit_master_email_modal: boolean = false;
    credentials_error_modal: boolean = false;
    duplicate_as_modal: boolean = false;
    duplicate_group_modal: boolean = false;
    send_test_email_modal: boolean = false;
    delete_group_modal: boolean = false;

    sending_email: boolean = false;
    sent_email: boolean = false;
    sent_email_id: string;

    loading: boolean = true;
    backup_loading: boolean = false;
    error: string;

    col_defs: ColDef[];
    preview_html_modal: boolean;
    preview_html: string;

    //registry selector
    selectedRegistry: string;
    allRegistries: any[];
    listRegistries: string[];

    constructor(
        private toastService: ToastService,
        private auth: AuthService,
        private fb: FormBuilder,
        private ddb_email_svc: DDBEmailTemplates,
        private route: Router,
        private cfg_svc: ConfigService,
        private em_svc: EmailMappingService,
        private cdr: ChangeDetectorRef,
        private environmentSvc: EnvironmentService,
        public translate: TranslateService
    ) {
        this.dynamic_replacement_form_group = this.fb.group({});
        this.form = this.fb.group({
            template_source_email: [this.DEFAULT_SOURCE_EMAIL, Validators.required],
            template_destination_emails: ['', Validators.required],
            duplicate_template: ['', Validators.required],
            selected_duplication_group: ['', Validators.required],
            selected_registry: ['', Validators.required],
            should_setup_email_mapping: ['', Validators.required],
            dynamic_replacement_variables: this.fb.array([]),
        });
    }

    ngAfterViewInit(): void {
        this.initialize = this.initialize.bind(this);
        this.initialize()
            .then(() => {
                this.environmentSvc.currentRegistry.subscribe((environment) => {
                    this.loadRegistrationContent(environment);
                });
            })
            .catch((err) => {
                console.error('Initialization failed:', err);
            });
    }

    async initialize(): Promise<void> {
        await this.reloadTemplates();
        this.col_defs = this.getTemplateTableColumns();
        this.cdr.detectChanges();
        this.user = await this.auth.getPulseAuth();
        this.user_type = this.user.type;
    }

    getTemplateTableColumns(): ColDef[] {
        return [
            {
                headerName: 'Name',
                field: 'name',
                filter: 'agTextColumnFilter',
                filterParams: {
                    caseSensitive: false,
                    defaultOption: 'contains',
                    suppressAndOrCondition: true,
                },
                flex: 2,
            },
            {
                headerName: 'Created Timestamp',
                field: 'created',
                filter: 'agDateColumnFilter',
                filterParams: {
                    filterOptions: ['inRange'],
                    comparator: (filterDate, cellValue) =>
                        composerUtils.agDateRange(filterDate, cellValue),
                },
                flex: 1,
            },
            {
                headerName: 'Actions',
                field: 'id',
                flex: 1,
                cellDataType: 'iconBtns',
                cellRendererParams: (params) => ({
                    actions: [
                        {
                            label: 'Edit / View',
                            isVisible: true,
                            onClick: () => {
                                this.triggerEditMasterEmailModal(params.data);
                            },
                            iconClass: 'fas fa-pencil-alt',
                            ['aria-label']: 'Edit template icon',
                            title: 'Edit',
                        },
                        {
                            label: 'Delete',
                            isVisible: true,
                            onClick: () => this.triggerDeleteConfimationModal(params.data),
                            iconClass: 'fas fa-trash',
                            ['aria-label']: 'Delete template icon',
                            title: 'Delete',
                        },
                        {
                            label: 'Copy',
                            isVisible: true,
                            onClick: () => this.triggerDuplicateAsModal(params.data),
                            iconClass: 'fas fa-copy',
                            ['aria-label']: 'Copy template icon',
                            title: 'Copy',
                        },
                        {
                            label: 'Send',
                            isVisible: true,
                            onClick: () => this.triggerSendTestEmailModal(params.data),
                            iconClass: 'far fa-paper-plane ',
                            ['aria-label']: 'Send test email icon',
                            title: 'Send',
                        },
                        {
                            label: 'Preview',
                            isVisible: true,
                            onClick: () => this.triggerPreviewModal(params.data),
                            iconClass: 'fa-solid fa-eye',
                            ['aria-label']: 'Preview email icon',
                            title: 'Preview',
                        },
                    ],
                }),
            },
        ];
    }

    reloadTemplates() {
        this.loading = true;

        this.delete_confirmation_modal = false;
        this.edit_master_email_modal = false;
        this.credentials_error_modal = false;
        this.duplicate_as_modal = false;
        this.send_test_email_modal = false;

        this.ddb_email_svc.listTemplates().subscribe((result: any) => {
            const templates = result;
            if (templates && templates.length > 0) {
                this.display_data = templates
                    .map((t) => {
                        const pkey =
                            t['registry_id#organization#site_id#template#language'].split('#');
                        if (pkey[0] === pkey[2]) {
                            pkey[2] = '';
                        }
                        // TODO it should not be possible to save a template without a key - we should work to remove the necessity for this.
                        if (pkey.length !== 5) {
                            this.showKeyErrorToast(pkey);
                            return null;
                        }
                        return {
                            name: `${pkey[0].toUpperCase()} _ ${pkey[2].toUpperCase()} _ ${pkey[3].toUpperCase()}_${pkey[4].toUpperCase()}`,
                            created: t.created,
                            'registry_id#organization#site_id#template#language':
                                t['registry_id#organization#site_id#template#language'],
                            header: t.header,
                            footer: t.footer,
                            body: t.body,
                            subject: t.subject,
                            reply_to_address: t.reply_to_address,
                            sender_email: t.sender_email,
                        };
                    })
                    .filter((t) => t !== null); // Remove null entries
                // .filter((t) => {
                //     const primaryKey = t['registry_id#organization#site_id#template#language'];
                //     const parts = primaryKey.split('#');

                //     const masterCount = parts.reduce((count, part) => {
                //         return count + (part.toLowerCase() === 'master' ? 1 : 0);
                //     }, 0);

                //     return masterCount < 2;
                // });
                // commented this to display master master templates
                this.display_data.sort((a, b) => a.name.localeCompare(b.name));

                this.filtered_templates = this.templates = templates;

                this.prefix_filters = templates.map((t) => {
                    return t['registry_id#organization#site_id#template#language']
                        .split('#')[0]
                        .toUpperCase();
                });

                this.prefix_filters = [...new Set(this.prefix_filters)];

                this.allRegistries = this.display_data;
                if (this.selectedRegistry) this.loadRegistrationContent(this.selectedRegistry);
            }

            this.cfg_svc.listRegistryConfig().subscribe((res) => {
                this.lst_registries = res;

                this.em_svc.list().subscribe((emRes) => {
                    this.lst_email_mapping = emRes;
                    this.loading = false;
                });
            });
        });
    }

    triggerEditMasterEmailModal(template: any) {
        this.selected_template = template;
        if (this.selected_template.name.includes('MASTER')) {
            this.edit_master_email_modal = true;
        } else {
            this.route.navigate([
                'emailtemplatemanager',
                'modify-template',
                this.selected_template,
            ]);
        }
    }

    closeDeleteEditMasterEmailModal() {
        this.selected_template = '';
        this.edit_master_email_modal = false;
    }

    triggerDeleteConfimationModal(template: any): void {
        this.selected_template = template;
        this.delete_confirmation_modal = true;
    }

    closeDeleteConfirmationModal(): void {
        this.selected_template = '';
        this.delete_confirmation_modal = false;
    }

    triggerDuplicateAsModal(template: any): void {
        this.selected_template = template;
        this.duplicate_as_modal = true;
    }

    closeDuplicateAsModal(): void {
        this.selected_template = '';
        this.duplicate_as_modal = false;
    }

    triggerDeleteGroupModal(): void {
        this.delete_group_modal = true;
    }

    closeDeleteGroupModal(): void {
        this.delete_group_modal = false;
    }

    async triggerSendTestEmailModal(template: any): Promise<void> {
        this.selected_template = template;
        this.dynamicReplacement(template);
        this.send_test_email_modal = true;
    }

    closeSendTestEmailModal(): void {
        this.selected_template = '';
        this.send_test_email_modal = false;
    }

    triggerPreviewModal(template: any): void {
        this.selected_template = template;
        this.loading = true;
        this.loadPreview(this.selected_template);
        this.loading = false;
        this.preview_html_modal = true;
    }
    closePreviewModal(): void {
        this.preview_html_modal = false;
    }

    deleteTemplate(context: any): void {
        context.ddb_email_svc
            .deleteTemplate(
                context.selected_template['registry_id#organization#site_id#template#language']
            )
            .subscribe(() => {
                context.reloadTemplates();
            });
    }

    deleteMultipleTemplates(template: any): void {
        this.ddb_email_svc
            .deleteTemplate(template['registry_id#organization#site_id#template#language'])
            .subscribe(() => {});
    }

    deleteGroup(context: any): void {
        context.group_validation = false;
        if (context.form.get('selected_duplication_group')) {
            const del_list = context.filtered_templates;
            Promise.all(del_list.map((temp) => context.deleteMultipleTemplates(temp))).then(() => {
                context.closeDeleteGroupModal();
                context.reloadTemplates();
            });
        } else {
            context.group_validation = true;
        }
    }

    duplicateCtaAction(context: any): void {
        if (context.form.get('duplicate_template').value) {
            context.duplicate_template = context.selected_template;
            context.duplicate_template['registry_id#organization#site_id#template#language'] =
                context.form.get('duplicate_template').value;

            context.ddb_email_svc.createTemplate(context.duplicate_template).subscribe(() => {
                context.route.navigate([
                    'emailtemplatemanager',
                    'modify-template',
                    context.duplicate_template,
                ]);
            });
        }
    }

    sendEmailSubmission(context: any): void {
        const formGroupValues = context.dynamic_replacement_form_group.value;
        const flatValues: { [key: string]: any } = {};

        //To remove the curly braces from a placeholder in an email and add that placeholder to a list as a replacement value
        for (const key in formGroupValues) {
            if (formGroupValues.hasOwnProperty(key)) {
                const cleanedKey = key.replace(/{{\s*|\s*}}/g, '');
                flatValues[cleanedKey] = formGroupValues[key];
            }
        }
        const replacement_values = flatValues;

        const destination_emails_input = context.form.value.template_destination_emails || '';
        let temp_destination_emails = destination_emails_input
            .split(/[,\s]+/)
            .filter((email) => email);

        if (temp_destination_emails.length === 0) {
            temp_destination_emails.push(context.user.emailAddr);
        }
        const name_split = context.selected_template.name.split('_');
        const temp_type = name_split[name_split.length - 2].trim().toLowerCase();

        const temp_source_email = context.form.value.template_source_email || '';

        const patient_id = context.user.user_id;
        const temp_registry = context.selected_template[
            'registry_id#organization#site_id#template#language'
        ]
            .split('#')[0]
            .trim();

        const email = {
            'registry_id#organization#site_id#template#language':
                context.selected_template['registry_id#organization#site_id#template#language'],
            template_data: {
                ...replacement_values,
                registry: temp_registry,
            },
            template_type: temp_type,
            destination_emails: temp_destination_emails,
            source_email: temp_source_email,
            patient_id: patient_id,
        };

        context.sending_email = true;
        context.sent_email = false;
        context.sent_email_id = '';

        context.ddb_email_svc
            .sendEmail(email)
            .toPromise()
            .then((res) => {
                context.showToastSuccess();
                context.send_test_email_modal = false;
                context.sending_email = false;
                context.sent_email = true;
            })
            .catch((err) => {
                context.showToastFailure();
                context.error = err;
                context.send_test_email_modal = false;
                context.sending_email = false;
            });
    }

    showToastSuccess() {
        this.toastService.show({
            type: 'success',
            body: this.translate.instant('AlertText.SentEmailSuccessfully'),
        });
    }

    showToastFailure() {
        this.toastService.show({
            type: 'error',
            body: this.translate.instant('AlertText.CannotSendEmail'),
        });
    }

    // TODO deprecate once this is not possible
    showKeyErrorToast(key) {
        this.toastService.show({
            type: 'error',
            body: this.translate.instant('AlertText.MalformedEmailTemplateKey') + ' ' + key,
        });
    }

    masterEmailProceed(context: any) {
        context.route.navigate([
            'emailtemplatemanager',
            'modify-template',
            context.selected_template,
        ]);
    }

    public loadPreview(params) {
        this.preview_html = '';
        if (params.header == undefined) {
            params.header = '';
        }
        if (params.footer == undefined) {
            params.footer = '';
        }
        const split_pkey = params['registry_id#organization#site_id#template#language'].split('#');
        this.selected_template.template_type = split_pkey[3];
        this.selected_template.registry_id = split_pkey[0];
        const preview = {
            ...params,
            template_type: this.selected_template.template_type,
            template_data: {
                registry: this.selected_template.registry_id,
            },
        };

        return this.ddb_email_svc
            .previewHTML(preview)
            .toPromise()
            .then((res) => {
                this.preview_html = res;
            });
    }

    //Params: Params refers too the template being sent
    //This function scans all replacement variables in the HTML and displays them to be used when sending the email.
    dynamicReplacement(params: any) {
        this.dynamic_replacement_variables = [];
        const regex = /{{\s*([\w]+)\s*}}/g;

        const header_matches = params.header ? params.header.match(regex) || [] : [];
        const footer_matches = params.footer ? params.footer.match(regex) || [] : [];
        const body_matches = params.body ? params.body.match(regex) || [] : [];

        this.dynamic_replacement_variables = [
            ...header_matches,
            ...footer_matches,
            ...body_matches,
        ];

        this.dynamic_replacement_variables = Array.from(new Set(this.dynamic_replacement_variables))
            .map((variable) => variable.replace(/{{\s*|\s*}}/g, ''))
            .filter(
                (variable) =>
                    variable.toLowerCase() !== 'url' &&
                    variable.toLowerCase() !== 'guid' &&
                    variable.toLowerCase() !== 'daysreportto18' &&
                    variable.toLowerCase() !== 'xxxxxxxx'
            );

        // Remove old controls
        Object.keys(this.dynamic_replacement_form_group.controls).forEach((key) => {
            this.dynamic_replacement_form_group.removeControl(key);
        });

        // Add new controls
        this.dynamic_replacement_variables.forEach((variable) => {
            this.dynamic_replacement_form_group.addControl(variable, this.fb.control(''));
        });
    }

    loadRegistrationContent(registry) {
        this.selectedRegistry = registry;
        this.display_data = this.allRegistries?.filter((value) => {
            return (
                value['registry_id#organization#site_id#template#language'].split('#')[0] ===
                registry
            );
        });
    }
}
