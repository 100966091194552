<div *ngIf="mode === 'view'; else formModeTemplate">
    <div *ngIf="!disabled" class="pi-control-edit-mode">
        <app-pi-form-view-response
            [element]="element"
            [theFormControl]="formControl"
            [displayValue]="stringValue"
        ></app-pi-form-view-response>
    </div>
</div>
<ng-template #formModeTemplate
    ><div *ngIf="!disabled" class="row pi-control-form-mode">
        <label
            *ngIf="element.titleLocation !== 'hidden'"
            class="form-label"
            [attr.for]="elementUID"
            [ngClass]="
                !element.orientation || element.orientation === 'horizontal'
                    ? 'col-12 col-md-6 col-lg-8'
                    : 'col-12'
            "
        >
            <app-element-title [element]="element"></app-element-title>
        </label>
        <div *ngIf="element.override">
            <p class="sub-title">
                {{ element.subTitle }}
            </p>
        </div>

        <div
            [ngClass]="
                (!element.orientation ||
                    element.orientation === 'horizontal' ||
                    element.titleLocation !== 'hidden') &&
                !element.override
                    ? 'col-12 col-md-6 col-lg-4'
                    : 'col-12'
            "
        >
            <app-loading-component [loading]="loading">
                <div #dropdownWrapper class="dropdown-wrapper">
                    <div class="dropdown-container" (click)="toggleDropdown()">
                        <!-- Dropdown input area -->
                        <div class="dropdown-input" [ngClass]="{ active: dropdownOpen }">
                            <!-- Placeholder for when no items are selected -->
                            <span
                                *ngIf="currentChoice === '' && !element.override"
                                class="placeholder"
                            >
                                {{
                                    element.optionsCaption || 'Registry.SelectResponse'
                                        | translate
                                        | titlecase
                                }}
                            </span>

                            <span
                                *ngIf="currentChoice === '' && element.override"
                                class="placeholder"
                            >
                                {{ element.choices[0]?.text | titlecase }}
                            </span>

                            <!-- Show the current choice -->
                            <span *ngIf="currentChoice !== ''" class="selected-tag">
                                {{ getCurrentChoiceName() | titlecase }}
                            </span>

                            <!-- Arrow icon positioned on the right -->
                            <i
                                class="fas fa-chevron-down"
                                [ngClass]="{
                                    up: dropdownOpen,
                                    down: !dropdownOpen,
                                }"
                            ></i>
                        </div>

                        <!-- Dropdown list (displayed when open) -->
                        <div class="dropdown-list" *ngIf="dropdownOpen">
                            <!-- Placeholder option -->
                            <div
                                *ngIf="!element.override"
                                class="dropdown-item"
                                (click)="
                                    selectionMade(''); $event.stopPropagation(); toggleDropdown()
                                "
                            >
                                {{
                                    element.optionsCaption || 'Registry.SelectResponse'
                                        | translate
                                        | titlecase
                                }}
                            </div>

                            <!-- List of options -->
                            <div
                                *ngFor="let choice of element.choices"
                                class="dropdown-item"
                                [ngClass]="{ selected: currentChoice === choice.value }"
                                (click)="
                                    selectionMade(choice.value);
                                    $event.stopPropagation();
                                    toggleDropdown()
                                "
                            >
                                {{ choice.name || getText(choice.text) || choice | titlecase }}

                                <i class="fas fa-check" *ngIf="currentChoice === choice.value"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </app-loading-component>
        </div>
        <app-validator
            [control]="formControl"
            [customErrorMessages]="customErrorMessages"
            [warningControl]="warningControl"
            [customWarningMessages]="customWarningMessages"
        ></app-validator>
    </div>
</ng-template>
