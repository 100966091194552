<div class="container-fluid panel-control">
    <div class="row">
        <div class="col">
            <div class="card">
                <div class="card-body data-table-ctrl-1200">
                    <div class="d-flex justify-content-between">
                        <h1>Question list</h1>
                        <div class="d-flex justify-content-end mb-2">
                            <app-button
                                class="mx-3"
                                (click)="refresh()"
                                [iconConfig]="{ iconClasses: 'fa-regular fa-rotate-right' }"
                                [design]="'primary'"
                            />
                            <app-button
                                (click)="openQuestionBuilder()"
                                class="mx-3"
                                [buttonText]="'Create new question'"
                                [iconConfig]="{
                                    iconFirst: true,
                                    iconClasses: 'fa-regular fa-plus',
                                }"
                                [design]="'primary'"
                            />
                        </div>
                    </div>
                    <app-loading-component [loading]="loading">
                        <app-table-component
                            pagination="true"
                            [paginationPageSize]="25"
                            [columnDefs]="colDefs"
                            [rowData]="questionList"
                        >
                        </app-table-component>
                    </app-loading-component>
                </div>
            </div>
        </div>
    </div>
</div>
