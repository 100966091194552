<span
    id="element.id"
    [attr.title]="getText(element.title) || element.name"
    [innerHTML]="getText(element.title) || element.name"
>
</span>

<span *ngIf="element.isRequired" class="required-star">
    <i class="fa-solid fa-asterisk"></i>
</span>

<span *ngIf="element.isRequiredWarn" class="warn-star">
    <i class="fa-solid fa-asterisk"></i>
</span>

<span *ngIf="element.tooltip">
    <!-- TODO I think we should just use html to do image tooltips 
        this split check will try to render any tooltip starting with https as an image
    ASF Diagnostics uses it and potentially other surveys -->
    <span
        *ngIf="getText(element.tooltip).split(':')[0] === 'https'; else textToolTip"
        class="imageTooltip"
    >
        <span><i aria-label="Info icon" class="fa-solid fa-info-circle"></i></span>
        <span class="tooltip-content clearfix">
            <img [src]="getText(element.tooltip)" />
        </span>
    </span>
    <ng-template #textToolTip>
        <span [tooltip]="getText(element.tooltip)" [options]="tooltipOptions" class="ms-2">
            <i aria-label="Info icon" class="fa-solid fa-info-circle"></i>
        </span>
    </ng-template>
</span>

<div
    class="ps-3 fs-6 text-left fst-italic text-muted"
    *ngIf="
        (element.addSubheading !== false && element.subheading) ||
        (element.description &&
            element.descriptionLocation &&
            element.descriptionLocation === 'underTitle')
    "
    [innerHtml]="element.subheading ?? element.description"
></div>
