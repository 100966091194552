import { CombinedClaimsObject } from './combinedClaimsObject';
import { UserRole } from './role';
import { UserStatus } from './status';
import { Settings } from '@h20-services/enums/settings/settings';

// To Do: Make pulseAuth a singleton;
// The constructor is called more than once, meaning there could be more than one instance of pulseAuth in a session.
// User claims are occasionally updated during a session.
// If the class is not a singleton, the instances of pulseAuth created before the update will be unchanged.
// If claims are checked against the unchanged instances, they could be incorrect.
export class PulseAuth {
    user_id: string = '';
    site_ID: string = '';
    emailAddr: string = '';
    tableau_username = '';
    isLoggedIn: boolean = false;
    mfa_required: boolean = false;
    mfa_status = '';
    authenticationID = '';
    preferredName = '';
    organizationID = '';
    type: string = '';
    status: string = '';
    isPatientProxy: boolean = false;
    greenlight_patient_ID: string = '';
    registryKey: string = '';
    claims: any;
    participants: any[] = [];
    multipleJurisdictions: boolean = false;
    multipleRoles: boolean = false;
    roles = [];
    jurisdictions = [];
    currentRole: string;
    currentJurisdiction: string;
    first_name: string;
    last_name: string;
    mobile_phone: number;
    street_address: string;
    date_of_birth: string;
    postal_code: string;
    gender: string;
    combinedClaims: CombinedClaimsObject;
    sites: string[];
    multipleSites = false;
    settings = {};

    constructor(attributes, session) {
        if (session) {
            this.isLoggedIn = true;
            if (session.tokens.idToken.payload?.user_data) {
                const userData = JSON.parse(session.tokens.idToken.payload?.user_data);
                this.user_id = userData.id || '';
                this.site_ID = userData.site_ID || '';
                this.emailAddr = userData.email || userData.username || '';
                this.type = userData.type || '';
                this.authenticationID = userData.authentication_ID || '';
                this.organizationID = userData.organization_ID || '';
                this.status = userData.status || '';
                this.mfa_required = userData.mfa_required;
                this.mfa_status = userData.mfa_status || '';
                this.preferredName = userData.preferred_name;
                this.isPatientProxy = userData.isPatientProxy;
                this.greenlight_patient_ID = userData.greenlight_patient_ID;
                this.registryKey = userData.registry_key;
                this.first_name = userData.first_name;
                this.last_name = userData.last_name;
                this.mobile_phone = userData.mobile_phone;
                this.street_address = userData.street_address;
                this.date_of_birth = userData.date_of_birth;
                this.postal_code = userData.postal_code;
                this.gender = userData.gender;
            }

            if (!this.preferredName) this.preferredName = session.token?.idToken?.payload?.nickname;
            if (attributes) this.tableau_username = attributes?.['custom:tableau_username'] || '';

            this.settings = JSON.parse(session.tokens.idToken.payload?.user_settings ?? '{}');
            this.jurisdictions = JSON.parse(session.tokens.idToken.payload.jurisdictions ?? '[]');
            this.combinedClaims = JSON.parse(session.tokens.idToken.payload?.user_claims ?? '[]');
            this.participants = JSON.parse(
                session.tokens.idToken.payload.user_participants ?? '[]'
            );

            const claimsKey = sessionStorage.getItem('claims_key');
            // If the claims key is present in the session storage when pulseAuth is instantiated, set the role based on the claims key.
            // Otherwise the user is not fully logged in yet and setRole will be called when login is complete.
            if (claimsKey) {
                const parsedKey = JSON.parse(claimsKey);
                this.setRole(parsedKey.site_ID, parsedKey.role);
            }
        }
    }

    public getUserID(): string {
        return this.user_id;
    }

    public getSiteID(): string {
        return this.site_ID;
    }

    public getEmailAddr(): string {
        return this.emailAddr;
    }

    public setEmailAddr(addr): PulseAuth {
        this.emailAddr = addr || '';
        return this;
    }

    public getTableauUsername(): string {
        return this.tableau_username;
    }

    public doesSiteMatch(s: string): boolean {
        return s == this.site_ID;
    }

    public getType(): any {
        return this.type;
    }

    public isType(t: UserRole): boolean {
        return this.type == t;
    }

    public getAuthenticationID(): string {
        return this.authenticationID;
    }

    public getPreferredName(): string {
        return this.preferredName;
    }

    public getOrganizationID(): string {
        return this.organizationID;
    }

    public getStatus(): string {
        return this.status;
    }

    public isStatusInArray(ary: UserStatus[]): boolean {
        if (!ary) return false;
        for (const us of ary) {
            if (this.status == us) return true;
        }
        return false;
    }

    public isTypeInArray(ary: UserRole[]): boolean {
        if (!ary) return false;
        for (const urs of ary) {
            if (this.type == urs) return true;
        }
        return false;
    }

    public isBothPatientAndProxy(): boolean {
        return this.isPatientProxy;
    }

    public getMFARequired(): boolean {
        return this.mfa_required;
    }

    public getMFAStatus(): string {
        return this.mfa_status;
    }

    public getGreenlightPatientId(): string {
        return this.greenlight_patient_ID;
    }

    public getRegistryKey(): string {
        return this.registryKey;
    }

    public getParticipants(): any[] {
        return this.participants;
    }

    public getClaims(): any {
        return this.claims;
    }

    public hasMultipleJurisdictions() {
        return this.multipleJurisdictions;
    }

    public hasMultipleRoles() {
        return this.multipleRoles;
    }

    public getRoles() {
        return this.roles;
    }

    getSites() {
        return this.sites;
    }

    public getJurisdictions() {
        return this.jurisdictions;
    }

    public getCurrentRegistry() {
        return location.hostname.split('.')[0];
    }

    public getCurrentRole() {
        return this.currentRole;
    }

    public static NotLoggedIn: PulseAuth = new PulseAuth(null, null);
    public getUserDetails() {
        return {
            firstName: this.first_name,
            lastName: this.last_name,
            mobile: this.mobile_phone,
            streetAddress: this.street_address,
            postalCode: this.postal_code,
            dob: this.date_of_birth,
            gender: this.gender,
        };
    }

    public getSetting(setting: Settings) {
        return this.settings[setting];
    }

    public setRole(site_id, role) {
        const registry_key = location.hostname.split('.')[0];
        this.site_ID = site_id;
        this.currentRole = role;
        this.type = role;

        // When switching sites within a registry, the current role of the user may not be available in the new site.
        // Check and switch to the first role available in the new site if the current role is not available.
        if (!this.combinedClaims[registry_key][site_id][role]) {
            this.currentRole = Object.keys(this.combinedClaims[registry_key][site_id])[0];
        }

        this.claims = this.combinedClaims[registry_key][this.site_ID][this.currentRole];
        this.roles = Object.keys(this.combinedClaims[registry_key][site_id]);
        this.sites = Object.keys(this.combinedClaims[registry_key]);

        // for persistence of the current role selection if the page is refreshed.
        sessionStorage.setItem(
            'claims_key',
            JSON.stringify({
                registry_key: registry_key,
                site_ID: this.site_ID,
                role: this.currentRole,
            })
        );

        this.setJurisdictionsFlags();
    }

    private setJurisdictionsFlags() {
        // For visibility of dropdowns
        if (this.sites.length > 1) this.multipleSites = true;

        if (this.roles.length > 1) this.multipleRoles = true;

        if (this.jurisdictions.length > 1) this.multipleJurisdictions = true;
    }

    public getRoleKey() {
        const registry_key = location.hostname.split('.')[0];
        return { registry_key: registry_key, site_ID: this.site_ID, role: this.currentRole };
    }

    public setMultipleRoles(bool: boolean) {
        this.multipleRoles = bool;
    }

    public getCombinedClaims() {
        return this.combinedClaims;
    }
    public hasMultipleSites() {
        return this.multipleSites;
    }
}
