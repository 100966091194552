import { Injectable } from '@angular/core';
import { map, Observable, of } from 'rxjs';
import { environment as env } from '@environment/environment';
import { ConfigService } from './config.service';
import { UserRole } from './models/role';
import { RequestService } from './request.service';
import { UserStatus } from './models/status';
import { CommonService } from './common.service';

import { UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Cacheable } from './helpers/cache.decorator';
import { utils } from './utils/utils';
import { CacheService } from './cache.service';
import { GetUserResponse } from './models/userServiceResponseTypes';
import { SettingsObject } from './enums/settings/settings';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    site: string;

    constructor(
        private reqSvc: RequestService,
        private configsvc: ConfigService,
        private translate: TranslateService,
        private commonSvc: CommonService,
        private cacheService: CacheService
    ) {}

    // ---------------------- Core CRUD methods for user ----------------------------------- //

    updateUser(updateUserInput: any) {
        const filteredInput = utils.pick(updateUserInput, [
            'id',
            'status',
            'site_ID',
            'excludeFromReporting',
        ]);

        const req: any = {
            operation: 'update',
            data: filteredInput,
        };
        return this.reqSvc.post(env.API.writeToDBUser, req);
    }

    getUser(id: string): Observable<GetUserResponse> {
        let dataToSend = {
            id: id,
        };
        const req: any = { operation: 'get', data: dataToSend };
        //endpoint sends as an array for no reason, so just return first result
        return this.reqSvc.post(env.API.writeToDBUser, req).pipe(
            map((x) => {
                return x[0];
            })
        );
    }

    // Call requestUserByAuthId to get user data from db
    // If the prt has both proxy and patient role, it will return proxy's data
    getUserByAuthId(authId: string) {
        return new Promise((resolve, reject) => {
            this.getValidUsersByAuthId(authId).then(
                (users) => {
                    if (Object.keys(users).length == 1) {
                        resolve(users[0]);
                    } else if (Object.keys(users).length > 1) {
                        // If more that two rows returned, return proxy data
                        let proxyData = null;
                        let tempPrefName = null;
                        for (let i = 0; i < Object.keys(users).length; i++) {
                            if (users[i].type == UserRole.Proxy) {
                                proxyData = users[i];
                                // Set flag to know they have both pat and proxy roles
                                proxyData['isPatientProxy'] = true;
                            }
                            if (users[i].preferred_name) {
                                tempPrefName = users[i].preferred_name;
                            }
                        }
                        proxyData['preferred_name'] = tempPrefName;
                        resolve(proxyData);
                    } else {
                        resolve(null);
                    }
                },
                (err: any) => {
                    console.error(err);
                    reject(err);
                }
            );
        });
    }

    @Cacheable()
    getValidUsersByAuthId(authId: string) {
        const req: any = {
            operation: 'getValidUsersByAuthId',
            data: {
                authId: authId,
            },
        };

        return this.reqSvc.post(env.API.writeToDBUser, req).toPromise();
    }

    getSiteReconciliationDataByUserID(id: string) {
        // TODO: Need endpoint - Returning stub
        return of({
            id,
            compare: ['healthie', 'geisinger'],
            firstname: {
                label: 'First name',
                name: 'firstname',
                healthie: 'Hubert',
                geisinger: 'Hubert',
                canEdit: false,
            },
            middlename: {
                label: 'Middle name',
                name: 'middlename',
                healthie: 'Blaine',
                geisinger: 'Blaine',
                canEdit: false,
            },
            lastname: {
                label: 'Last name',
                name: 'lastname',
                healthie: 'Wolfeschlegelsteinhousenbergerdorf, Sr.',
                geisinger: 'Wolfeschlegelsteinhousenbergerdorf, Sr.',
                canEdit: false,
            },
            reason: {
                label: 'Reason',
                name: 'reason',
                healthie: 'Deceased, undergoing treatment',
                geisinger: 'n/a',
                canEdit: true,
                copyDirection: 'both',
            },
            dob: {
                label: 'Date of birth',
                name: 'dob',
                healthie: '10/25/1936',
                geisinger: 'Hubert Blaine Wolfeschlegelsteinhousenbergerdorf, Sr.',
                canEdit: true,
                copyDirection: 'both',
            },
            sex: {
                label: 'Sex assigned at birth',
                name: 'sex',
                healthie: 'n/a',
                geisinger: 'Male',
                canEdit: true,
                copyDirection: 'both',
                optional: true,
            },
            ethnicity: {
                label: 'Ethnicity',
                name: 'ethnicity',
                healthie: 'unknown',
                geisinger: 'Not Hispanic or Latino',
                canEdit: true,
                copyDirection: 'both',
            },
        });
    }

    /* --------------------- Special methods for user ------------------------- */

    //check user from Cognito
    getUserAdmin(userName: string) {
        let dataToSend = {
            username: userName,
        };

        const req: any = { operation: 'getUser', data: dataToSend };
        return this.reqSvc.post(env.API.userServiceProvider, req);
    }

    createUser(user: any, created_ID: any) {
        let dataToSend = {
            site_ID: user.siteid,
            type: user.role,
            email: user.username,
            username: user.username,
            nickname: user.nickname,
            created_ID: created_ID,
            mfaRequired: user.mfaRequired,
            excludeFromReporting: user.excludeFromReporting,
            tableauUsername: user.tableauUsername,
        };
        const req: any = { operation: 'createUser', data: dataToSend };
        return this.reqSvc.post(env.API.writeToDBUser, req);
    }

    updateUserAdmin(id: any, user: any, created_ID: any) {
        let dataToSend = {
            id: id,
            username: user.username,
            site_ID: user.siteid,
            type: user.role,
            nickname: user.nickname,
            created_ID: created_ID,
            mfaRequired: user.mfaRequired,
            mfaStatus: user.mfaStatus,
            tableauUsername: user.tableauUsername,
            mfaReset: user.mfaReset,
        };

        const req: any = { operation: 'updateUser', data: dataToSend };
        return this.reqSvc.post(env.API.writeToDBUser, req);
    }

    removeUserFromGroup(username: string, role: string) {
        let dataToSend = {
            role: role,
            username: username,
        };

        const req: any = { operation: 'removeUserFromGroup', data: dataToSend };
        return this.reqSvc.post(env.API.userServiceProvider, req);
    }

    addUserToGroup(username: string, role: string) {
        let dataToSend = {
            role: role,
            username: username,
        };

        const req: any = { operation: 'addUserToGroup', data: dataToSend };
        return this.reqSvc.post(env.API.userServiceProvider, req);
    }

    disableUserAdmin(id: string, username: string, created_ID: any) {
        let dataToSend = {
            username: username,
            id: id,
            created_ID: created_ID,
        };

        const req: any = { operation: 'disable', data: dataToSend };
        return this.reqSvc.post(env.API.writeToDBUser, req);
    }

    enableUserAdmin(id: string, username: string, created_ID: any) {
        let dataToSend = {
            username: username,
            id: id,
            created_ID: created_ID,
        };

        const req: any = { operation: 'enable', data: dataToSend };
        return this.reqSvc.post(env.API.writeToDBUser, req);
    }

    deleteUserAdmin(id: string, username: string, created_ID: any) {
        let dataToSend = {
            username: username,
            id: id,
            created_ID: created_ID,
        };

        const req: any = { operation: 'delete', data: dataToSend };
        return this.reqSvc.post(env.API.writeToDBUser, req);
    }

    updateUserStatus(userID: string, uStatus: UserStatus) {
        const variables = { id: userID, status: uStatus };
        return this.updateUser(variables);
    }

    //SITE ADMIN, need notifications of a patient withdrawal/ or when a new patient consents to the site
    sendAdminNotification(userId: string, patientEmail: string) {
        let dataToSend = {
            userId: userId,
            patientEmail: patientEmail,
            registry: this.configsvc.getTenant(),
        };
        const req: any = {
            operation: 'sendAdminNotification',
            data: dataToSend,
        };
        return this.reqSvc.post(env.API.sendAdminNotification, req);
    }

    sendAdminNotification_PatientConsent(patientId: string) {
        let dataToSend = {
            patientId: patientId,
            registry: this.configsvc.getTenant(),
        };
        const req: any = {
            operation: 'sendAdminNotificationPatientConsent',
            data: dataToSend,
        };
        return this.reqSvc.post(env.API.sendAdminNotification, req);
    }

    sendAdminNotification_PatientRegister(userId: String) {
        let dataToSend = {
            userId: userId,
            registry: this.configsvc.getTenant(),
        };
        const req: any = {
            operation: 'sendAdminNotificationPatientRegister',
            data: dataToSend,
        };
        return this.reqSvc.post(env.API.sendAdminNotificationPrtRegister, req);
    }

    sendAdminNotification_NonPrtActivation(userId: string) {
        let dataToSend = {
            userId: userId,
            registry: this.configsvc.getTenant(),
        };
        const req: any = {
            operation: 'sendAdminNotification_NonPrtActivation',
            data: dataToSend,
        };
        return this.reqSvc.post(env.API.sendAdminNotification, req);
    }

    saveUserSettings(settings: SettingsObject) {
        const req: any = {
            operation: 'put',
            data: settings,
        };

        return this.reqSvc.post(env.API.userSettingsService, req);
    }

    getUsersByType(type: string) {
        let dataToSend = {
            type: type,
        };
        const req: any = {
            operation: 'getUsersByType',
            data: dataToSend,
        };
        return this.reqSvc.post(env.API.writeToDBUser, req);
    }

    // ToDo: Removed logInfo to fix param validation error, need to revisit how to store audit logs
    getUsersByTypesSiteID(
        userId: string,
        types: any,
        siteId: any,
        logInfo: any = null,
        refreshPage?: boolean
    ) {
        const cacheKey = `users_${userId}`;
        let dataToSend = {
            types: types,
            site_ID: siteId,
        };
        const req: any = {
            operation: 'getUsersByTypesSiteID',
            data: dataToSend,
        };
        return this.cacheService.cacheObservable(
            cacheKey,
            this.reqSvc.post(env.API.writeToDBUser, req),
            0,
            refreshPage
        );
    }

    getUsersByTypesRegistry(
        userId: string,
        types: any,
        logInfo: any = null,
        refreshPage?: boolean
    ) {
        const cacheKey = `usersRegistry_${userId}`;
        let dataToSend = {
            registryKey: this.configsvc.getTenant(),
            types: types,
        };
        const req: any = {
            operation: 'getUsersByTypesRegistry',
            data: dataToSend,
        };
        return this.cacheService.cacheObservable(
            cacheKey,
            this.reqSvc.post(env.API.writeToDBUser, req),
            0,
            refreshPage
        );
    }

    // Update contact data from survey response for proxy and patient
    updateContactFromSurvey(patientId, proxyId, surveyRes) {
        let dataToSend = {
            patient_ID: patientId,
            surveyResponse: surveyRes,
        };

        if (proxyId != null) {
            dataToSend['proxy_ID'] = proxyId;
        }

        const req: any = {
            operation: 'updateContactFromSurvey',
            data: dataToSend,
        };
        return this.reqSvc.post(env.API.writeToDBUser, req);
    }

    // Change the auth id to the new cognito sub.
    updateAuthIdByOldAuthId(oldAuthId: any, authId: any) {
        let dataToSend = {
            oldAuthId: oldAuthId,
            authId: authId,
        };
        const req: any = {
            operation: 'updateAuthIdByOldAuthId',
            data: dataToSend,
        };
        return this.reqSvc.post(env.API.writeToDBUser, req);
    }

    // Update MFA status
    updateMfaByAuthId(mfaStatus: string, mfaRequired: boolean, authId: string) {
        let dataToSend = {
            mfaStatus: mfaStatus,
            mfaRequired: mfaRequired,
            authId: authId,
        };
        const req: any = {
            operation: 'updateMfaByAuthId',
            data: dataToSend,
        };
        return this.reqSvc.post(env.API.writeToDBUser, req);
    }

    updateAttributeToCogUser(email: string, attributeName: string, attributeValue: string) {
        let dataToSend = {
            username: email,
            attributeName: attributeName,
            attributeValue: attributeValue,
        };
        const req: any = {
            operation: 'updateAttributeToCogUser',
            data: dataToSend,
        };
        return this.reqSvc.post(env.API.writeToDBUser, req);
    }

    getUserAttributeDefinitions(envName: string) {
        const targetEnv = this.commonSvc.getTargetEnv(envName);
        const req: any = {
            operation: 'listUserAttributeDefinitions',
            data: {},
        };
        return this.reqSvc.post(targetEnv.API.writeToDBUserAttribute, req, targetEnv);
    }

    saveUserAttributeDefinition(envName: string, attributeDefinition: any) {
        const targetEnv = this.commonSvc.getTargetEnv(envName);
        let dataToSend = {
            attribute_definition: JSON.stringify(attributeDefinition),
        };
        const req: any = {
            operation: 'saveUserAttributeDefinition',
            data: dataToSend,
        };
        return this.reqSvc.post(targetEnv.API.writeToDBUserAttribute, req, targetEnv);
    }

    evaluateUserAttribute(envName: string, userId: string, attributeId: string) {
        const targetEnv = this.commonSvc.getTargetEnv(envName);
        let dataToSend = {
            user_id: userId,
            attribute_id: attributeId,
        };
        const req: any = {
            operation: 'evaluateUserAttribute',
            data: dataToSend,
        };
        return this.reqSvc.post(targetEnv.API.writeToDBUserAttribute, req, targetEnv);
    }

    setUserAttribute(envName: string, userId: string, attributeId: string) {
        const targetEnv = this.commonSvc.getTargetEnv(envName);
        let dataToSend = {
            user_id: userId,
            attribute_id: attributeId,
        };
        const req: any = {
            operation: 'setUserAttribute',
            data: dataToSend,
        };
        return this.reqSvc.post(targetEnv.API.writeToDBUserAttribute, req, targetEnv);
    }

    updateAfterSignUp(id: string, authId: string, type: string, username: string) {
        const dataToSend = {
            id: id,
            authId: authId,
            type: type,
            username: username,
        };
        const req: any = {
            operation: 'updateAfterSignUp',
            data: dataToSend,
        };
        return this.reqSvc
            .post(env.API.writeToDBUser, req)
            .toPromise()
            .catch((err) => {
                throw err;
            });
    }

    movePatientToAnotherSite(id: string, site_ID: string) {
        let dataToSend = {
            id: id,
            site_ID: site_ID,
        };
        const req: any = {
            operation: 'movePatientToAnotherSite',
            data: dataToSend,
        };
        return this.reqSvc.post(env.API.writeToDBUser, req);
    }

    validatePasswords(formGroup: UntypedFormGroup, type?: string): any {
        let len;
        const formGroupPass = formGroup.get('password');
        const formGroupConfirm = formGroup.get('confirmPassword');
        const inputs = formGroup.value;
        const lower = /[a-z]/g.test(inputs.password);
        const upper = /[A-Z]/g.test(inputs.password);
        const special = /[@~`!#$%\^&*+=\-\[\]\\';,./{}|\\":<>\?]/g.test(inputs.password);
        const num = /[0-9]/g.test(inputs.password);
        if (type == UserRole.Patient || type == UserRole.Proxy) {
            len = inputs.password.length >= 8 ? true : false;
        } else {
            len = inputs.password.length >= 12 ? true : false;
        }
        const displayStrs = this.translate.store.translations[this.translate.currentLang];

        let pwValidationMessage = null;
        if (!len) {
            if (type == UserRole.Patient || type == UserRole.Proxy) {
                pwValidationMessage = displayStrs.Login.PasswordLengthEight;
            } else {
                pwValidationMessage = displayStrs.Login.PasswordLengthTwelve;
            }
        } else if (!lower) {
            pwValidationMessage = displayStrs.Login.PasswordLowercaseLetter;
        } else if (!upper) {
            pwValidationMessage = displayStrs.Login.PasswordUppercaseLetter;
        } else if (!num) {
            pwValidationMessage = displayStrs.Login.PasswordIncludeNumber;
        } else if (!special) {
            pwValidationMessage = displayStrs.Login.PasswordIncludeSpecialCharacter;
        }

        let flag = null;
        if (!lower || !upper || !special || !num || !len) {
            flag = { noMatch: true };
            formGroupPass.setErrors(flag);
        } else {
            pwValidationMessage = null;
            formGroupPass.setErrors(flag);
        }
        if (inputs.password !== inputs.confirmPassword) {
            flag = { notSame: true };
            formGroupConfirm.setErrors(flag);
        } else {
            flag = null;
            formGroupConfirm.setErrors(flag);
        }

        return {
            ...flag,
            pwValidationMessage: pwValidationMessage,
        };
    }
}
