import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { RequestService } from './request.service';
import { environment as env } from '@environment/environment';
import { map } from 'rxjs';
@Injectable({
    providedIn: 'root',
})
export class QuestionService {
    constructor(
        private reqSvc: RequestService,
        private commonSvc: CommonService
    ) {}
    getQuestionList() {
        const req: any = { operation: 'get', data: '' };
        return this.reqSvc.post(env.API.writeToDBQuestions, req);
    }
    getQuestionConfigById(id) {
        const req: any = { operation: 'getById', data: { id: id } };
        return this.reqSvc.post(env.API.writeToDBQuestions, req).pipe(
            map((x) => {
                return x[0];
            })
        );
    }
}
