<div *ngIf="mode === 'view'; else formModeTemplate">
    <div *ngIf="!disabled" class="pi-control-edit-mode">
        <app-pi-form-view-response
            [element]="element"
            [theFormControl]="formControl"
            [displayValue]="stringValue"
        ></app-pi-form-view-response>
    </div>
</div>
<ng-template #formModeTemplate
    ><div *ngIf="!disabled" class="row pi-control-form-mode">
        <label class="form-label col-12 col-md-6 col-lg-8" [attr.for]="elementUID">
            <app-element-title [element]="element"></app-element-title>
        </label>
        <div class="col-12 col-md-6 col-lg-4 p-0 m-0">
            <app-loading-component [loading]="loading">
                <div
                    class="dropdown-container"
                    (click)="toggleDropdown()"
                    [name]="elementUID"
                    [attr.id]="elementUID"
                    [attr.aria-label]="getText(element.title)"
                    [attr.disabled]="element.readOnly"
                >
                    <!-- Dropdown input area -->
                    <div class="dropdown-input" [ngClass]="{ active: dropdownOpen }">
                        <!-- Placeholder for when no items are selected -->
                        <span *ngIf="selectedItems.length === 0" class="placeholder"
                            >Select items</span
                        >

                        <!-- Selected items as tags (if any) -->
                        <ng-container *ngIf="selectedItems.length > 0">
                            <span *ngFor="let selectedItem of selectedItems" class="selected-tag">
                                {{
                                    selectedItem.name || getText(selectedItem.text) || selectedItem
                                }}
                                <span
                                    class="remove-tag"
                                    (click)="
                                        toggleSelection(selectedItem); $event.stopPropagation()
                                    "
                                    >x</span
                                >
                            </span>
                        </ng-container>

                        <!-- Arrow icon positioned on the right -->
                        <i class="arrow" [ngClass]="{ up: dropdownOpen, down: !dropdownOpen }"></i>
                    </div>

                    <!-- Dropdown list (displayed when open) -->
                    <div
                        class="dropdown-list"
                        *ngIf="dropdownOpen"
                        [ngClass]="{ scrollable: scrollable }"
                    >
                        <input
                            *ngIf="enableSearch"
                            class="search-input"
                            type="text"
                            [(ngModel)]="searchQuery"
                            placeholder="Search..."
                            (click)="$event.stopPropagation()"
                        />
                        <div
                            *ngFor="let item of filteredItems"
                            class="dropdown-item"
                            (click)="toggleSelection(item); $event.stopPropagation()"
                        >
                            <span>{{ item.text }}</span>
                            <i *ngIf="selectedItems.includes(item)" class="checkmark">&#10003;</i>
                        </div>
                    </div>
                </div>
            </app-loading-component>
            <div class="mt-2" *ngIf="element.clearSelection">
                <app-button
                    class="col-3"
                    (action)="clearSelection_Click()"
                    [disabled]="disabledClearSelection"
                    [buttonText]="'Clear selection'"
                    design="tertiary"
                    [iconConfig]="{
                        iconClasses: 'fa-regular fa-ban me-2',
                        iconFirst: true,
                    }"
                />
            </div>
        </div>
        <app-validator
            [control]="formControl"
            [customErrorMessages]="customErrorMessages"
            [warningControl]="warningControl"
            [customWarningMessages]="customWarningMessages"
        ></app-validator>
    </div>
</ng-template>
