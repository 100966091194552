<!-- publish modal -->
<app-modal
    *ngIf="showPublishModal"
    [saveBtnText]="'Registry.Continue'"
    [(visible)]="showPublishModal"
    [header]="'Publish Survey Metadata'"
    [context]="this"
    [saveFunc]="publishSurvey"
    [modalWidth]="'40vw'"
    [modalHeight]="'45vh'"
    [closeFunc]="closePublishSurveyModal"
>
    <p class="h5 font-weight-bold">{{ selectedPublishSurveyTitle }}</p>
    <p>
        This is to update survey metadata and question key mapping to the destination environment.
    </p>
    <p>If you done updating the survey, please deploy it to the destination first.</p>
    <p>(Please check again whether the destination you want to work in is correct.)</p>
    <hr />
    <p>You will see 'Confirm & Update Questions Key Mapping' if continue.</p>
</app-modal>

<!-- select registry modal -->
<app-modal
    *ngIf="showSelectRegistryModal"
    [(visible)]="showSelectRegistryModal"
    [header]="'Select one registry'"
    [context]="this"
    [closeBtnText]="Close"
    [showCloseBtn]="true"
    [closeFunc]="closeSelectRegistryModal"
    [modalWidth]="'40vw'"
    [modalHeight]="'50vh'"
>
    <div class="form-group">
        <select class="form-select" (change)="assignSelectedRegistry($event.target.value)">
            <option [selected]="true" disabled>Please Select</option>
            <option *ngFor="let reg of registries" [value]="reg.registry_ID">
                {{ reg.registry_name }}
            </option>
        </select>
    </div>
</app-modal>

<!-- confirm question key modal -->
<app-modal
    *ngIf="showConfirmQuestionKeyModal"
    [(visible)]="showConfirmQuestionKeyModal"
    [header]="'Confirm & Update Question Key Mapping'"
    [context]="this"
    [closeFunc]="closeConfirmQuestionKeyModal"
    [closeBtnText]="'Close'"
    [showCloseBtn]="true"
    [saveBtnText]="'Submit'"
    [saveFunc]="saveQuestionKeyMapping"
>
    <div class="row">
        <p class="h5 font-weight-bold">{{ selectedPublishSurveyTitle }}</p>
        <div>
            <app-loading-component [loading]="loadingConfirmQuestionKey">
                <app-table-component [columnDefs]="colDefsQKey" [rowData]="flatElementList">
                </app-table-component>
            </app-loading-component>
        </div>
    </div>
</app-modal>

<div class="container-fluid panel-control">
    <div class="row">
        <div class="col">
            <div class="card">
                <div class="card-body data-table-ctrl-1200">
                    <div>
                        <h1>Survey Deployment</h1>
                        <form>
                            <div class="form-group">
                                <app-button
                                    [disabled]="!isRegistrySelected"
                                    class="mx-3"
                                    (action)="deployByRegistry()"
                                    [buttonText]="'Registry.DeployByRegistry'"
                                />
                                <app-button
                                    [disabled]="!isRegistrySelected"
                                    class="mx-3"
                                    (action)="deployRegistryColumn()"
                                    [buttonText]="'Deploy Registry Column'"
                                />
                                <app-button
                                    class="mx-3"
                                    (action)="deployBySelected()"
                                    [buttonText]="'Deploy By Selected'"
                                />
                            </div>
                        </form>
                    </div>
                    <div class="d-flex justify-content-end">
                        <app-button
                            [iconConfig]="{ iconClasses: 'fa-regular fa-sliders-v' }"
                            [buttonClasses]="'ms-1'"
                            style="opacity: 0.5; cursor: default; margin-bottom: 8px"
                            (action)="showToast()"
                        />
                    </div>
                    <div *ngIf="listOfUnmachtedSurveyIds" class="col mx-5">
                        <p>Below are unmatched Survey ids:</p>
                        {{ listOfUnmachtedSurveyIds }}
                    </div>
                    <app-loading-component [loading]="deployLoading"> </app-loading-component>
                    <div *ngIf="message" class="text-center text-danger mb-2">
                        {{ message }}
                    </div>
                    <div>
                        <app-loading-component [loading]="loading">
                            <app-table-component
                                [rowData]="surveys"
                                [columnDefs]="colDefs"
                                [filterModelKey]="'deploy_survey'"
                                pagination="true"
                                rowSelection="multiple"
                                (selectionChanged)="onSelect($event)"
                                suppressRowClickSelection="true"
                            >
                            </app-table-component>
                        </app-loading-component>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
