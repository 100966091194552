import { Component, Input } from '@angular/core';
import { NgClass } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-generic-badge',
    imports: [NgClass, TranslateModule],
    standalone: true,
    templateUrl: './generic-badge.component.html',
    styleUrls: ['./generic-badge.component.scss'],
})
export class GenericBadgeComponent {
    @Input() size: 'sm' | 'md' | 'lg' = 'md';
    @Input() color: 'client' | 'gray' | 'blue' | 'red' | 'yellow' | 'green' | 'transparent' =
        'gray';
    @Input({ required: true }) label!: string;
}
