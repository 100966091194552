import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import jwt_decode from 'jwt-decode';
import { fetchAuthSession } from '@aws-amplify/auth';
import { ClaimService } from '@h20-services/claim.service';
import { AuthService } from '@h20-services/auth.service';
import { PulseAuth } from '@h20-services/models/PulseAuth';
import { CombinedClaimsObject } from '@h20-services/models/combinedClaimsObject';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard {
    role = 'guest';
    constructor(
        private router: Router,
        private claimService: ClaimService,
        private auth: AuthService
    ) {}
    userClaims;

    canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
        return new Promise((resolve) => {
            fetchAuthSession()
                .then((authSession) => {
                    const token = authSession.tokens.idToken.toString();
                    const guardClaims = route.data.claims as Array<string>;

                    let decodedJwt: any = this.getDecodedAccessToken(token);
                    const { registry_key, site_ID, role } = JSON.parse(
                        sessionStorage.getItem('claims_key')
                    );
                    const combinedClaims: CombinedClaimsObject = JSON.parse(
                        decodedJwt?.user_claims
                    );
                    // At this point, we have the user claims will be null if they are not authorized to visit this registry_key+site_ID
                    this.userClaims = combinedClaims[registry_key][site_ID][role] ?? null;
                    if (!this.userClaims) {
                        console.error('Misconfigured user; No roles for user. Login not possible');
                        return false;
                    }

                    this.claimService.setUserClaims(this.userClaims);

                    if (!token || !guardClaims) {
                        // no jwt (not logged in); or no roles specified for this guard
                        console.warn('Access denied; not logged in and/or route misconfigured.');
                        this.router.navigate(['/login']);
                        resolve(false);
                    }

                    if (this.userClaims && Object.keys(this.userClaims).length === 0) {
                        var isClaimOn = true;
                        guardClaims.forEach((cl: string) => {
                            // Check for the key which is the claim's name
                            if (cl in this.userClaims) {
                                isClaimOn = this.userClaims[cl];
                            } else if ('all' in this.userClaims) isClaimOn = true;
                        });

                        if (isClaimOn) {
                            resolve(true);
                        } else {
                            console.warn('Access denied.');
                            this.router.navigate(['/login']);
                            resolve(false);
                        }
                    } else {
                        var isClaimOn = false;
                        guardClaims.forEach((cl: string) => {
                            if (cl in this.userClaims) {
                                isClaimOn = this.userClaims[cl];
                            }
                        });

                        if (isClaimOn) {
                            resolve(true);
                        } else {
                            console.warn('Access denied.');
                            this.router.navigate(['/login']);
                            resolve(false);
                        }
                    }
                })
                .catch((err) => {
                    console.error(err);
                    this.router.navigate(['/login']);
                    resolve(false);
                });
        });
    }

    searchData(state: RouterStateSnapshot, dataKey: string): string | null {
        if (state.root.data && state.root.data[dataKey]) {
            return state.root.data[dataKey];
        }
        let child: ActivatedRouteSnapshot | null;
        child = state.root.firstChild;
        while (child != null) {
            if (child.data && child.data[dataKey]) {
                return child.data[dataKey];
            }
            child = child.firstChild;
        }
        return null;
    }
    getDecodedAccessToken(token: string): any {
        try {
            return jwt_decode(token);
        } catch (err) {
            console.error(err);
            return null;
        }
    }
}
