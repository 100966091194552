import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { QuestionService } from '@h20-services/question.service';
import { QuestionStatus } from '../models/questionStatus';
import { CustomFilterComponent } from '@h20-shared/table-component/custom-filter/custom-filter.component';

@Component({
    selector: 'app-question-list',
    templateUrl: './question-list.component.html',
    styleUrls: ['./question-list.component.scss'],
})
export class QuestionListComponent implements OnInit {
    questionList;
    loading = true;
    colDefs;
    constructor(
        private questionService: QuestionService,
        private datePipe: DatePipe,
        private router: Router
    ) {}
    ngOnInit(): void {
        this.colDefs = this.getColumnDef();
        this.getQuestionList();
    }

    copyCellData(value) {
        navigator.clipboard.writeText(value);
    }
    getColumnDef() {
        return [
            {
                headerName: 'Question text',
                field: 'title',
                filter: 'agTextColumnFilter',
                pinned: 'left',
            },
            {
                headerName: 'Question ID',
                field: 'question_id',
                autoHeight: true,
                cellDataType: 'textWithIcon',
                filter: true,

                cellRendererParams: (params) => {
                    return {
                        iconPosition: 'before',
                        btnClass: '',
                        iconClass: 'text-muted fa-regular fa-copy',
                        ['aria-label']: 'Copy',
                        iconText: params?.value.substring(0, 8),
                        onClick: (value) => {
                            this.copyCellData(value);
                        },
                    };
                },
            },
            {
                headerName: 'Element type',
                field: 'element_type',
                filter: 'agTextColumnFilter',
            },
            {
                headerName: 'Approval Status',
                field: 'status',
                cellDataType: 'badgeText',
                cellRendererParams: (params) => {
                    return this.getBadge(params?.value);
                },
                filter: CustomFilterComponent,
                filterParams: {
                    data: this.getBadge.bind(this),
                    options: ['approved', 'draft', 'not approved'],
                },
            },
            {
                headerName: 'Approved By',
                filter: 'agTextColumnFilter',
            },
            {
                headerName: 'Created By',
                field: 'created_by',
                filter: 'agTextColumnFilter',
            },
            {
                headerName: 'Created date',
                field: 'created',
                filter: 'agTextColumnFilter',

                valueGetter: (params) => this.datePipe.transform(params.data.created, 'yyyy-MM-dd'),
            },
            {
                headerName: 'Last Modified By',
                field: 'modified_by',
                filter: 'agTextColumnFilter',
            },
            {
                headerName: 'Last Modified',
                field: 'modified',
                filter: 'agTextColumnFilter',
                valueGetter: (params) =>
                    this.datePipe.transform(params.data.modified, 'yyyy-MM-dd'),
            },
            {
                headerName: 'Version',
                field: 'version',
                filter: 'agTextColumnFilter',
            },
            {
                headerName: 'Actions',
                field: 'id',
                pinned: 'right',
                suppressSizeToFit: false,
                wrapText: false,
                initialWidth: 100,
                cellDataType: 'openAndActionBtns',
                autoHeight: true,
                cellRendererParams: (params) => {
                    return {
                        viewIcon: false,
                        iconClass: 'fa-regular fa-ellipsis',
                        actions: [
                            {
                                isVisible: true,
                                label: 'Quick view',
                                showIcon: true,
                                // onClick: () => this.openQuestionBuilder(params.data),
                                iconClass: 'fa-regular fa-eye',
                                title: 'View',
                            },
                            {
                                isVisible: true,
                                label: 'View and edit',
                                showIcon: true,
                                onClick: () => this.openQuestionBuilder(params.data),
                                iconClass: 'fa-light fa-sliders-simple',
                                title: 'Edit',
                            },
                            {
                                isVisible: true,
                                label: 'Duplicate',
                                showIcon: true,
                                // onClick: (event) => this.deleteSurvey(event, params.data),
                                iconClass: 'fa-regular fa-copy',
                                title: 'Delete',
                            },
                        ],
                    };
                },
            },
        ];
    }

    getBadge(value) {
        if (value === QuestionStatus.approved) {
            return {
                badgeColor: 'green',
                text: value,
            };
        } else if (value === QuestionStatus.draft) {
            return {
                badgeColor: 'gray',
                text: value,
            };
        } else if (value === QuestionStatus.notApproved) {
            return {
                badgeColor: 'red',
                text: value,
            };
        }
    }
    openQuestionBuilder(params = null) {
        if (params) this.router.navigate(['surveys/question-build/' + params.question_id]);
        else this.router.navigate(['surveys/question-build/']);
    }
    getQuestionList() {
        this.questionService.getQuestionList().subscribe((response) => {
            this.loading = false;
            this.questionList = response;
        });
    }
    refresh() {
        this.loading = true;
        this.getQuestionList();
    }
}
