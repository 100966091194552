import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { from, Observable, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { fetchAuthSession } from 'aws-amplify/auth';
import { environment } from '@environment/environment';
import { lastValueFrom } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root',
})
export class RequestService {
    constructor(private http: HttpClient) {}

    post(url: string, requestBody: any, env?: any): Observable<any> {
        return from(fetchAuthSession())
            .pipe(
                mergeMap(({ tokens }) => {
                    const options = {};
                    if (env) {
                        options['headers'] = {
                            ...env.API.headers,
                        };
                    } else {
                        options['headers'] = {
                            ...environment.API.headers,
                        };
                    }

                    if (tokens?.idToken) {
                        options['headers']['Authorization'] = tokens.idToken.toString();
                    }
                    const claimsKey = sessionStorage.getItem('claims_key');
                    if (claimsKey) {
                        requestBody['claims-key'] = claimsKey;
                    }

                    return this.http.post(url, requestBody, options).pipe(
                        catchError((e: Error) => {
                            return throwError(() => e);
                        })
                    );
                })
            )
            .pipe(
                catchError((e) => {
                    try {
                        if (
                            !e ||
                            (!e.hasOwnProperty('status') && e.toLowerCase() === 'no current user')
                        ) {
                            const options = {};
                            options['headers'] = {
                                ...environment.API.headers,
                            };

                            return this.http.post(url, requestBody, options).pipe(
                                catchError((e: Error) => {
                                    return throwError(() => e);
                                })
                            );
                        }
                    } catch (err) {
                        console.error('unable to handle exception type', err);
                    }
                    return throwError(() => e);
                })
            );
    }

    async put(url, data, env?): Promise<any> {
        const source$ = from(fetchAuthSession())
            .pipe(
                mergeMap(({ tokens }) => {
                    const options = {};

                    return this.http.put(url, data, options).pipe(
                        catchError((e: Error) => {
                            return throwError(() => e);
                        })
                    );
                })
            )
            .pipe(
                catchError((e) => {
                    try {
                        if (
                            !e ||
                            (!e.hasOwnProperty('status') && e.toLowerCase() === 'no current user')
                        ) {
                            const options = {};

                            return this.http.put(url, data, options).pipe(
                                catchError((e: Error) => {
                                    return throwError(() => e);
                                })
                            );
                        }
                    } catch (err) {
                        console.error('unable to handle exception type', err);
                    }
                    return throwError(() => e);
                })
            );
        return await lastValueFrom(source$);
    }

    async get(url, env?): Promise<any> {
        const source$ = from(fetchAuthSession())
            .pipe(
                mergeMap(({ tokens }) => {
                    const options = { responseType: 'blob' as 'json' };

                    return this.http.get(url, options).pipe(
                        catchError((e: Error) => {
                            return throwError(() => e);
                        })
                    );
                })
            )
            .pipe(
                catchError((e) => {
                    try {
                        if (
                            !e ||
                            (!e.hasOwnProperty('status') && e.toLowerCase() === 'no current user')
                        ) {
                            const options = { responseType: 'blob' as 'json' };

                            return this.http.get(url, options).pipe(
                                catchError((e: Error) => {
                                    return throwError(() => e);
                                })
                            );
                        }
                    } catch (err) {
                        console.error('unable to handle exception type', err);
                    }
                    return throwError(() => e);
                })
            );
        return await lastValueFrom(source$);
    }
}
