import { Component, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR, ValidatorFn, Validators } from '@angular/forms';
import { IFormElement } from '@h20-services/models/forms/IFormElement';
import { AbstractPiControlComponent } from '../abstract-pi-control/abstract-pi-control.component';

@Component({
    selector: 'app-pi-control-number',
    templateUrl: './pi-control-number.component.html',
    styleUrls: ['./pi-control-number.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: PiControlNumberComponent,
        },
    ],
})
export class PiControlNumberComponent
    extends AbstractPiControlComponent<IFormElement, string>
    implements OnInit
{
    ngOnInit() {
        super.ngOnInit();
        // This validator is for validating against another question in the survey

        this.formControl.addValidators(this.validators());
    }

    validators() {
        const compareDateValidatorFn: ValidatorFn = () => {
            const errors: any = this.compareDateValidator();
            return Object.keys(errors).length > 0 ? errors : null;
        };

        return compareDateValidatorFn;
    }

    textInput(text) {
        this.markAsTouched();
        this.writeValue(text.target.value);
        this.notifyValueChange();
    }
}
