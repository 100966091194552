import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DDBEmailTemplates } from '@h20-services/ddb-email.service';
import { Template } from '@h20-services/models/emails/email_template';
import { ConfigService } from '@h20-services/config.service';
import { SiteService } from '@h20-services/site.service';
import { TemplateTypes } from '@h20-services/models/emails/email_template_types';
import { Quill } from 'quill';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
    selector: 'app-modify-templates',
    templateUrl: './modify-templates.component.html',
    styleUrls: ['./modify-templates.component.scss'],
})
export class ModifyTemplatesComponent implements OnInit {
    //Params: Params relates to the selected template being edited, if no template it selected, params refers to the current template being created.
    // quillEditorInstance: Quill;
    preferred_language: string = '';
    split_primary_key: string[];
    preview_html_panel: boolean = false;

    body_html: string;
    subject: string;
    isNewTemplate: boolean;
    template: Template;
    rte_text: string;
    lstRegistries: string[] = [];
    lstSites: string[] = [];
    lstOrganizations: string[] = ['default'];
    lst_template_types: string[] = [];
    lst_languages: Set<string> = new Set<string>();
    primary_key: string;

    selectedRegistry: any;
    selectedSite: string;
    selectedOrganization: string;
    selected_template_type: TemplateTypes;
    selected_language: string;

    reply_to_address: string = 'no-reply@healthie.net';
    sender_email: string = 'no-reply@healthie.net';
    opening_tag: string = `<td bgcolor="#FFFFFF" style="background-color: #ffffff; padding: 10px 21px 22px 21px">`;
    closing_tag: string = `</td>`;

    registryRequired: boolean = false;
    validationError: boolean = false;

    loading: boolean = true;
    submitLoading: boolean = false;

    disabled: boolean = false;
    duplicate: boolean;

    hasCTA: boolean = true;
    buttonText: string = 'Click Here';
    buttonColor: string = '#7c04e4';
    buttonTextColor: string = '#ffffff';
    buttonLinkType: 'activate' | 'login' = 'activate';

    quillEditorInstanceTop: Quill;
    quillEditorInstanceBottom: Quill;

    htmlMode: boolean = false;

    bodyHtmlTop: string = '';
    bodyHtmlBottom: string = '';

    safePreviewHtml: SafeHtml;

    constructor(
        private ddb_email_svc: DDBEmailTemplates,
        private route: ActivatedRoute,
        private router: Router,
        private config_svc: ConfigService,
        private site_svc: SiteService,
        private sanitizer: DomSanitizer
    ) {}

    ngOnInit(): void {
        this.route.params.subscribe((params) => {
            if (params.name) {
                this.isNewTemplate = false;
                this.disabled = true;
                this.loadInitialData(
                    params['registry_id#organization#site_id#template#language']
                ).then(() => {
                    if (this.template.subject == null) {
                        this.template.subject = '';
                    }
                    if (this.template.body == null) {
                        this.template.body = '';
                    }

                    if (this.selectedOrganization && this.selectedRegistry && this.selectedSite) {
                        const { bodyTop, bodyBottom, buttonConfig } = this.parseTemplateBody(
                            this.template.body
                        );
                        this.bodyHtmlTop = bodyTop;
                        this.bodyHtmlBottom = bodyBottom;
                        this.subject = this.template.subject;

                        if (buttonConfig) {
                            this.buttonText = buttonConfig.text;
                            this.buttonLinkType = buttonConfig.linkType;
                            this.buttonColor = buttonConfig.backgroundColor;
                            this.buttonTextColor = buttonConfig.textColor;
                        }
                        this.generateButtonPreviewHtml();
                        this.loadPreview(this.template);
                        this.loading = false;
                    }
                });
            } else {
                this.isNewTemplate = true;
                this.template = new Template('', '', '', '', '', '');
                this.loadInitialData().then(() => {
                    if (this.lstRegistries && this.lstOrganizations && this.lstSites) {
                        this.loading = false;
                    }
                });
            }
        });
    }

    parseTemplateBody(body: string): { bodyTop: string; bodyBottom: string; buttonConfig: any } {
        // Use regex to find the opening and closing tags, allowing for whitespace
        const openingTagRegex = /<td[^>]*>\s*/i;
        const closingTagRegex = /\s*<\/td>/i;

        const openingTagMatch = body.match(openingTagRegex);
        const closingTagMatch = body.match(closingTagRegex);

        if (!openingTagMatch || !closingTagMatch) {
            return { bodyTop: body, bodyBottom: '', buttonConfig: null };
        }

        const contentStart = openingTagMatch.index + openingTagMatch[0].length;
        const contentEnd = closingTagMatch.index;
        const content = body.substring(contentStart, contentEnd);

        const buttonRegex =
            /<p\s+(?:style="[^"]*(?:\s*text-align:\s*center\s*)[^"]*"|class="[^"]*ql-align-center[^"]*")>\s*<a\s+href="(?!mailto:)([^"]*)"[^>]*style="([^"]*(?:background-color|color|border-radius|padding)[^"]*)"[^>]*>([\s\S]*?)<\/a>\s*<\/p>/i;
        const buttonMatch = content.match(buttonRegex);

        let bodyTop = '',
            bodyBottom = '',
            buttonConfig = null;

        if (buttonMatch) {
            this.hasCTA = true;
            const [fullMatch, href, style, buttonText] = buttonMatch;
            const buttonIndex = content.indexOf(fullMatch);
            bodyTop = content.substring(0, buttonIndex).trim();
            bodyBottom = content.substring(buttonIndex + fullMatch.length).trim();

            const backgroundColorMatch = style.match(
                /background-color:\s*(#[0-9A-Fa-f]{6}|[a-zA-Z]+)/i
            );
            const textColorMatch = style.match(
                /(?<!background-)color:\s*(#[0-9A-Fa-f]{6}|[a-zA-Z]+)/i
            );

            buttonConfig = {
                text: buttonText.trim(),
                linkType: href.includes('login') ? 'login' : 'activate',
                backgroundColor: backgroundColorMatch ? backgroundColorMatch[1] : '#006ec7',
                textColor: textColorMatch ? textColorMatch[1] : '#201e1e',
            };
        } else {
            bodyTop = content;
            this.hasCTA = false;
        }

        return { bodyTop, bodyBottom, buttonConfig };
    }

    adjustTextareaHeight(event: Event): void {
        const textarea = event.target as HTMLTextAreaElement;
        textarea.style.height = 'auto';
        textarea.style.height = `${textarea.scrollHeight}px`;
    }

    async loadInitialData(pkey?: string): Promise<void> {
        if (pkey) {
            this.primary_key = pkey;
            this.split_primary_key = pkey.split('#');
            this.selectedRegistry = this.split_primary_key[0];
            this.selectedOrganization = this.split_primary_key[1];
            this.selectedSite = this.split_primary_key[2];
            this.selected_template_type = this.split_primary_key[3] as TemplateTypes;
            this.selected_language = this.split_primary_key[4];

            await this.loadRegistries();
            await this.loadTemplate(pkey);
            await this.loadSites();
            await this.loadTemplateTypes();
            await this.loadLanguages();
        } else {
            await this.loadRegistries();
            await this.loadSites();
            await this.loadTemplateTypes();
        }
    }

    async loadRegistries(): Promise<void> {
        try {
            const res = await this.config_svc.listRegistryConfig().toPromise();
            this.lstRegistries = res
                .filter((reg) => {
                    return (
                        reg.registry_id.toString() !== 'proto-ui-copy' &&
                        reg.registry_id.toString() !== 'disabled-curve' &&
                        reg.registry_id.toString() !== 'disabled-grasp'
                    );
                })
                .map((reg) => reg.registry_id.toString());
            this.lstRegistries.push('master');
        } catch (error) {
            console.error('Error loading registries:', error);
        }
    }

    async loadSites(): Promise<void> {
        try {
            if (this.selectedRegistry !== 'master') {
                const res = await this.site_svc
                    .getSitesByRegistry(this.selectedRegistry)
                    .toPromise();
                this.lstSites = res.map((site) => site.name);
                this.lstSites.push('master');
            } else {
                this.lstSites.length = 0;
                this.lstSites.push('master');
            }
        } catch (err) {
            console.error(err);
        }
    }

    async loadTemplateTypes(): Promise<void> {
        return this.ddb_email_svc
            .listTemplateTypes()
            .toPromise()
            .then((template) => {
                this.lst_template_types = template;
            });
    }

    async loadTemplate(pkey: string): Promise<void> {
        return this.ddb_email_svc
            .getTemplate(pkey)
            .toPromise()
            .then((template) => {
                this.template = template;
            });
    }

    async getTemplate(pkey: string): Promise<any> {
        return this.ddb_email_svc.getTemplate(pkey).toPromise();
    }

    async loadLanguages(): Promise<void> {
        this.lst_languages.clear();
        this.ddb_email_svc.listTemplates().subscribe((templates) => {
            templates
                .filter((t) => {
                    return t['registry_id#organization#site_id#template#language'].includes(
                        this.selectedRegistry
                    );
                })
                .map((t) => {
                    const parts: string[] =
                        t['registry_id#organization#site_id#template#language'].split('#');
                    this.lst_languages.add(parts[parts.length - 1]);
                });
        });
    }

    public loadPreview(template) {
        template['registry_id#organization#site_id#template#language'] =
            `${this.selectedRegistry}#${this.selectedOrganization}#${this.selectedSite}#${this.selected_template_type}#${this.selected_language}`;

        const preview = {
            ...template,
            template_type: this.selected_template_type,
            template_data: {
                registry: this.selectedRegistry,
            },
        };

        preview.body = this.template.body;
        preview.subject = this.subject;
        return this.ddb_email_svc
            .previewHTML(preview)
            .toPromise()
            .then((res) => {
                this.safePreviewHtml = this.sanitizer.bypassSecurityTrustHtml(res);
            });
    }

    async saveTemplate(): Promise<void> {
        this.submitLoading = true;
        this.updateTemplateBody();
        //this.template.body = this.body_html;
        this.template['registry_id#organization#site_id#template#language'] =
            `${this.selectedRegistry}#${this.selectedOrganization}#${this.selectedSite}#${this.selected_template_type}#${this.selected_language}`;
        if (this.isNewTemplate) {
            await this.ddb_email_svc.createTemplate(this.template).toPromise();
        } else {
            await this.ddb_email_svc.updateTemplate(this.template).toPromise();
        }
        this.submitLoading = false;
        this.router.navigate(['/emailtemplatemanager']);
    }

    async submit(): Promise<void> {
        this.validationError = false;
        this.registryRequired = false;

        this.template = new Template(
            this.template.header,
            this.template.footer,
            this.template.body,
            this.template.subject,
            this.selectedRegistry,
            this.primary_key
        );
        if (this.selectedRegistry && this.selectedSite && this.selectedOrganization) {
            await this.saveTemplate();
        } else {
            this.registryRequired = true;
        }
    }

    //Since we only have default as the organization, we don't currently have any implementation for loading the organization
    async registryChange(value: any): Promise<void> {
        this.selectedRegistry = value;
        await this.loadSites();
        await this.loadLanguages();
        if (
            this.selectedOrganization &&
            this.selectedSite &&
            this.selected_language &&
            this.selected_template_type
        ) {
            try {
                await this.loadPreview(this.template);
            } catch (error) {
                console.error('Error: ', error);
            }
        }
    }

    async onOrgChange(value: any) {
        this.selectedOrganization = value;
    }

    onBodyTopChange(event: any) {
        if (!this.htmlMode) {
            this.bodyHtmlTop = event.html;
        } else {
            this.bodyHtmlTop = event.target.value;
        }
        this.updateTemplateBody();
        this.loadPreview(this.template);
    }

    onBodyBottomChange(event: any) {
        if (!this.htmlMode) {
            this.bodyHtmlBottom = event.html;
        } else {
            this.bodyHtmlBottom = event.target.value;
        }
        this.updateTemplateBody();
        this.loadPreview(this.template);
    }

    resetCTAValues(): void {
        this.buttonText = 'Click Here';
        this.buttonColor = '#7c04e4';
        this.buttonTextColor = '#ffffff';
        this.buttonLinkType = 'login';
    }

    toggleCTA(): void {
        this.hasCTA = !this.hasCTA;
        this.bodyHtmlBottom = '';
        if (!this.hasCTA) {
            this.resetCTAValues();
        }
        this.updateTemplateBody();
        this.loadPreview(this.template);
    }

    updateTemplateBody(): void {
        if (this.hasCTA) {
            this.template.body =
                this.opening_tag +
                this.bodyHtmlTop +
                this.generateCTAButton() +
                this.bodyHtmlBottom +
                this.closing_tag;
        } else {
            this.template.body = this.opening_tag + this.bodyHtmlTop + this.closing_tag;
        }
    }

    generateCTAButton(): string {
        const linkHref =
            this.buttonLinkType === 'activate' ? '{{url}}/activate/{{guid}}' : '{{url}}/login';
        return `
        <p style="
        text-align: center;
        margin-top: 16px;
        margin-bottom: 16px;
        ">
            <a href="${linkHref}" style="
            border: solid 1px ${this.buttonColor};
            border-radius: 1000px;
            box-sizing: border-box;
            cursor: pointer;
            display: inline-block;
            font-size: 18px;
            font-weight: bold;
            padding-top: 12px;
            padding-bottom: 12px;
            padding-left: 28px;
            padding-right: 28px;
            text-decoration: none;
            background-color: ${this.buttonColor};
            color: ${this.buttonTextColor};
            ">
                ${this.buttonText}
            </a>
        </p>
        `;
    }

    async onSiteChange(value: any) {
        this.selectedSite = value;
    }

    async onTemplateTypeChange(value: any) {
        this.selected_template_type = value;
        if (
            this.selectedOrganization &&
            this.selectedSite &&
            this.selected_language &&
            this.selected_template_type
        ) {
            const pkey = `${this.selectedRegistry}#${this.selectedOrganization}#${this.selectedSite}#${this.selected_template_type}#${this.selected_language}`;
            try {
                const template = await this.getTemplate(pkey);
                if (!template) {
                    this.duplicate = false;
                    throw new Error();
                }
                this.duplicate = true;
                this.template = template;
                this.subject = this.template.subject;
                this.body_html = this.template.body;
                await this.loadPreview(this.template);
            } catch {
                this.template.body = '';
                this.template.subject = '';
            }
        }
    }

    async onLanguageChange(value: any) {
        this.selected_language = value;
        if (
            this.selectedOrganization &&
            this.selectedSite &&
            this.selected_language &&
            this.selected_template_type
        ) {
            const pkey = `${this.selectedRegistry}#${this.selectedOrganization}#${this.selectedSite}#${this.selected_template_type}#${this.selected_language}`;
            const template = await this.getTemplate(pkey);
            if (template) {
                this.duplicate = true;
                this.template = template;
                this.subject = this.template.subject;
                this.body_html = this.template.body;
                await this.loadPreview(this.template);
            } else {
                this.duplicate = false;
                this.template.body = '';
                this.template.subject = '';
            }
        }
    }

    async onSubjectChange(value: any) {
        this.subject = value;
        await this.loadPreview(this.template);
    }

    onToggleChange(event: Event) {
        const inputElement = event.target as HTMLInputElement;
        this.htmlMode = inputElement.checked;
        this.updateTemplateBody();
    }

    onCtaChange(event: Event) {
        this.updateTemplateBody();
        this.loadPreview(this.template);
    }

    generateButtonPreviewHtml(): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(this.generateCTAButton());
    }
}
